<template>
  <v-container>
    <v-data-table 
      :headers="headers" 
      :items="cpfs" item-key="name" class="elevation-1 mt-4" :search="search"
      :loading="loading_pacientes_exames">
      <template v-slot:top>
        
        <v-row dense class="mx-3"> 
          <v-col cols="12">Exames disponíveis</v-col>
        </v-row>
        <v-row  dense class="mx-3"> 
          <v-col cols="12">
            <v-data-table 
              :items="resumo_exames"
              :headers="headers_resumo_exames"
              :loading="loading_resuno_exames"
              :hide-default-footer="true"
            >  
            </v-data-table>
          </v-col> 
        </v-row>
        <v-row dense class="mx-3">
          <v-col cols="3">
            <v-text-field v-model="arquivo" clearable outlined label="Nome" placeholder="Nome"
              @change="get_paciente()"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn class="py-7" outlined color="blue" block @click="get_paciente()">
              Pesquisar exames
            </v-btn>
          </v-col>
        </v-row>
       
        <v-row dense class="mx-3">
          <v-divider class="my-1"></v-divider>
        </v-row>
        <v-row>
          <v-col cols="10">
            <v-text-field v-model="search" label="Filtro" class="mx-4"></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        
        <div v-if="item.tipo=='xml'">
          <a target="_blank" v-for="(i,k) in item.arquivo" :key="k" :href="i.url"><v-icon>mdi-file-xml-box</v-icon></a>
        </div>
        
        <div v-else-if="item.tipo=='pdf'">
          <a target="_blank" v-for="(i,k) in item.arquivo" :key="k" :href="i.url"><v-icon>mdi-file-pdf-box</v-icon></a>
        </div>
        
        <div v-else>
          <Galeria :current-id="item.id" 
          :current-item="item.arquivo" 
          :current-arquivo="item" />
        </div>
 
      </template>
      <template v-slot:[`item.exame_`]="{item}">
        <span v-if="!item.exame.raiz">
          {{ item.exame }} 
        </span>
        <span v-else>
          {{ item.exame.raiz.count }} -
          {{ item.exame.raiz.nome }}
        </span>
      </template>
      <template v-slot:[`item.st_atime`]="{ item }">
        {{ item.st_atime  }}
      </template>
    </v-data-table>
  </v-container>
</template>
<script>

import AppEtlService from '@/service/AppEtlService'
const api = new AppEtlService();
var dt_atual = new Date()
const gruopBy = (arr, name)=>{
  return arr.reduce((a,v) => {
    const emp = v[name]
    const n='raiz'
    if(emp!=='') {
      if(!a[n]){
        a[n] = {
          count:0,
          nome:emp
        }
      }
      a[n].count++;
    }
    return a;
  },{});
}
export default {
  name: 'Lista',
  components: {
    Galeria: () => import('./Galeria.vue'),
  },
  data: () => ({
    search: '',
    nome: '',
    arquivo: '',
    resumo_exames: [],
    headers_resumo_exames: [{text:'Unidade', value: 'empresa'},{text:'Exame', value: 'exame'}],
    loading_resuno_exames: false,
    search_exames:true,
    date_fim: dt_atual.toISOString().split('T')[0],
    date_ini: new Date(dt_atual.setMonth(dt_atual.getMonth() - 1)).toISOString().split('T')[0],
    exame: '',
    unidade: '',
    pacientes: [],
    pacientes_exames: [], // pacientes que tem exames de imagens
    unidades: ['Matriz', 'Golden', 'Ilha', 'V+', 'SUS', 'Passeio'],
    exames: ['RETINOGRAFIA'],
    loading: false, 
    loading_empresas: false,
    loading_pacientes_exames: false,
    headers: [
      { text: 'CPF', align: 'start', sortable: true, value: 'cpf', },
      { text: 'Unidade', align: 'start', sortable: true, value: 'empresa.raiz.nome', },
      { text: 'Exame', align: 'start', sortable: true, value: 'exame_', },
      { text: 'criado em', align: 'start', sortable: true, value: 'st_atime', },
      { text: 'Qtd', align: 'start', sortable: true, value: 'qtd', },
      { text: 'Arquivo', align: 'center', sortable: true, value: 'acoes', },
    ]
  }),
  computed: {
    cpfs(){
      var lista = this.pacientes_exames.map((e)=>{
        return {
          id: e.id,
          cpf:this.found_cpf(e.arquivo) || '00000000000',
          exame:e.exame,
          empresa: e.empresa,
          st_atime: e.st_atime,
          tipo:e.tipo,
          url:e.url,
        }
      })
      const groupByKeys = lista.reduce((groups, item) => {
        const val1 = item.cpf;
        const val2 = item.tipo;
        if (!groups[val1]) {
          groups[val1] = {
            cpf:val1
          };
        }
        if (!groups[val1][val2]) {
          groups[val1][val2] = [];
        }
        groups[val1][val2].push(item);
        return groups;
      }, {});
      
      var arr = []
      Object.values(groupByKeys).forEach(e=>{
        if(e.jpg!==undefined) {
          var empresa = gruopBy(e.jpg, 'empresa')
          var exame = gruopBy(e.jpg, 'exame')
          arr.push({cpf:e.cpf, tipo:'jpg', empresa:empresa, exame:exame, arquivo:e.jpg, qtd:e.jpg.length})
        }
        if(e.bmp!==undefined) {
          empresa = gruopBy(e.bmp, 'empresa')
          exame = gruopBy(e.bmp, 'exame')
          arr.push({cpf:e.cpf, tipo:'bmp', empresa:empresa, exame:exame, arquivo:e.bmp, qtd:e.bmp.length})
        }
        if(e.xml!==undefined) {
          empresa = gruopBy(e.xml, 'empresa')
          exame = gruopBy(e.xml, 'exame')
          arr.push({cpf:e.cpf, tipo:'xml', empresa:empresa, exame:exame, arquivo:e.xml, qtd:e.xml.length})
        }

        if(e.pdf!==undefined){
          e.pdf.forEach(f=>{
            arr.push({
              cpf:e.cpf, 
              tipo:f.tipo, 
              empresa:{raiz:{nome:f.empresa}}, 
              st_atime:f.st_atime, 
              exame:f.exame, 
              url:f.url, arquivo:e.pdf, qtd:e.pdf.length})
          })
        }
      })
      return arr
    }
  },
  methods: {
    limpa(){
      this.cpf = ''
      this.nome = ''
    },  
    found_cpf(val){
        
        var regex1 = /\d{3}\.\d{3}\.\d{3}-\d{2}/;
        var regex2 = /\d{11}/;
        var matches = val.match(regex2);
        if(!matches){
          matches = val.match(regex1);
        }
        if(matches!==null){
          if(matches.length>0){
            
            return matches[0]
          }
        }
        return null
    },
    get_paciente() {
      this.loading_pacientes_exames = true
      let data = ['nome', 'arquivo']
      api.get_api(`arquivos/lista_pacientes/?files=imagens&${data.map(key => `${key}=${this[key]}`).join('&')}`)
        .then(response => 
          this.pacientes_exames = response.data
        )
        .catch(error => console.log(error))
        .finally(() => this.loading_pacientes_exames = false)
    }
    ,
    get_resumo_exames() {
      this.loading_resuno_exames = true
      api.get_api(`arquivos/lista_resumo_exames/`)
        .then(response => 
          this.resumo_exames = response.data
        )
        .catch(error => console.log(error))
        .finally(() => this.loading_resuno_exames = false)
    }
  }
  , mounted() { 
    this.$store.dispatch('btnLoading', false)
    this.get_resumo_exames()

  }
}
</script>